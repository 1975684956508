<template>
    <div>
      <div
        v-if="dados[0].dados.length > 0"
        class="px-2"
      >
        <b-overlay
          v-if="loadingShow"
          :show="loadingShow" 
          rounded="lg"
          opacity="0.6"
          class="mt-2"
        />
        <b-row
          class="no-gutters"
        >
          <b-col
            sm="12"
            md="12"
            lg="7"
            class="pr-sm-1 pr-0 mb-1 mt-sm-2"
          >
            <vue-perfect-scrollbar
              class="height-custom" 
              :settings="settings"
            >
              <div
                v-if="Object.keys(dadosSelecionados).length !== 0"
              >
                <div
                  v-if="opcaoSelecionada === 'Total'"
                  class="d-flex align-items-center justify-content-center height-custom"
                >
                  <div class="minimal-graph-size-pizza">
                    <doencas-cronicas-total
                      :key="childKey"
                      :dados="dados"
                    />
                  </div>
                </div>
                <div v-else>
                  <doencas-cronicas-doencas-bar
                    :dados="dadosSelecionados"
                    :key="childKey"
                    :descricao="descricao"
                  />
                </div>
              </div>
            </vue-perfect-scrollbar>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="5"
            class="d-flex text-center center-div justify-content-center  espacamento-top"
          >
            <div>
              <div class="mb-1">
                <h6 class="titulo_legendas">Escolha uma Doença:</h6>
              </div>
              <div
                class="d-flex justify-content-center align-content-center"
              >
                <v-select
                  v-model="opcaoSelecionada"
                  @input="atualizaTipoSelecionado"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="opcoesSelect"
                  :clearable="false"
                  class="select-custom-filtros"
                  label="descricao"
                >
                  <span slot="no-options">Nenhum filtro encontrado.</span>
                </v-select>
              </div>
              <div v-if="opcaoSelecionada === 'Total'" class="d-flex justify-content-center align-items-center mt-2 mb-sm-0 mb-2">
                <div class="grouping-escalas">
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sem-sintomas"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">0 doencas</div>
                    </div>
                    </div>
                    <div class="align-by-line">
                      <div  class="spacement-bullet">
                        <div class="bullet-escala bullet-color-sintomas-minimos"></div>
                      </div>
                      <div class="spacement-label">
                        <div class="item-escala">1 doencas</div>
                      </div>
                    </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-leves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">2 doencas</div>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-moderados"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">3 doencas</div>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-graves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">4 ou + doenças </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="opcaoSelecionada != 'Total'" class="d-flex justify-content-center align-items-center mt-2 mb-sm-0 mb-2">
                <div class="grouping-escalas">
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sem-sintomas"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Faz uso de medicação</div>
                    </div>
                    </div>
                    <div class="align-by-line">
                      <div  class="spacement-bullet">
                        <div class="bullet-escala bullet-color-sintomas-minimos"></div>
                      </div>
                      <div class="spacement-label">
                        <div class="item-escala">Não faz uso de medição</div>
                      </div>
                    </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-leves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Não precisa de medicação</div>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-moderados"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Não sabe se possui a doença</div>
                    </div>
                  </div>
                  <div class="align-by-line">
                    <div class="spacement-bullet">
                      <div class="bullet-escala bullet-color-sintomas-graves"></div>
                    </div>
                    <div class="spacement-label">
                      <div class="item-escala">Não possui a doença </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        v-else
        class="height-custom-grafico d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BOverlay
  } from "bootstrap-vue";
  import DoencasCronicasTotal from "@/views/components/custom/dashboard-planeja-saude/DoencasCronicasTotal";
  import DoencasCronicasDoencasBar from "@/views/components/custom/dashboard-planeja-saude/DoencasCronicasDoencasBar";
  import DoencasCronicasDoencas from "@/views/components/custom/dashboard-planeja-saude/DoencasCronicasDoencas.vue";
  import vSelect from "vue-select";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "./CardSemDados.vue";
  
  export default {
    components: { 
      VuePerfectScrollbar,
      DoencasCronicasTotal,
      vSelect,
      BRow,
      BCol,
      BOverlay,
      DoencasCronicasDoencasBar,
      DoencasCronicasDoencas,
      CardSemDados
    },
    props: {
      dados: {
        type: Array,
        required: true
      },
      FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    DataCicloTempoReal: {
      type: String,
      default() {
        return {};
      },
      required: true,
    },
    },
    data() {
      return {
        loadingShow: true,
        childKey: 0,
        descricao: null,
        opcaoSelecionada: null,
        selecionado: "Total",
        opcoesSelect: [
            "Total",
            "AVC (Acidente Vascular Cerebral)",
            "Artrite ou Reumatismo",
            "Asma ou Bronquite Crônica",
            "Colesterol ou Triglicérides Alto",
            "Câncer",
            "Depressão",
            "Diabetes",
            "Hipertensão Arterial"
        ],
        dadosSelecionados: {},
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.2,
          wheelPropagation: false,
        },
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0
        }
      };
    },
    mounted() { 
      this.addClasseColunaSelect();

      
      this.opcaoSelecionada = this.opcoesSelect[0]
      this.dadosSelecionados = this.$props.dados[0]
      this.loadingShow = false
    },
    methods: {
      atualizaTipoSelecionado(tipoString){
        this.descricao = tipoString
        const indexSelecionado = this.opcoesSelect.indexOf(tipoString)
        this.dadosSelecionados = this.$props.dados[indexSelecionado]
        this.childKey++
      },

      addClasseColunaSelect(){

        let element = document.querySelector('.v-select');

        // Verifica se o elemento foi encontrado
        if (element) {
          const childElement = element.querySelector('.vs__dropdown-toggle');
          if (childElement) {
            childElement.classList.add('col-12');
          }

        } 
      }
    }
  };
  </script>
  
  <style scoped>
  .height-custom{
    height:280px;
  }
  .height-custom-grafico{
    height: 240px;
  }
  .titulo_legendas{
    font-size: 16px;
    font-weight: bold;
  }
  .bullet-color-sintomas-minimos{
    background-color: #2772C0;
  }
  .bullet-color-sintomas-leves{
    background-color: #002060;
  }
  .bullet-color-sintomas-moderados{
    background-color: #7030A0;
  }
  .bullet-color-sintomas-graves{
    background-color: #AA228C;
  }
  .bullet-color-sem-sintomas{
    background-color: #36BA00;
  }
  .grouping-escalas{
    font-size: 14px;
    font-weight: 500;
  }
  .align-by-line{
    display: flex;
    align-items: center;
    height: 15px;
    margin-bottom: 12px;
  }
  .separador{
    margin-right: 7px;
  }
  .spacement-bullet{
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 8px;
  }
  .bullet-escala{
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .spacement-label{
    height: 100%;
    display: flex;
    align-items: center;
  }
  .item-escala{
    text-align: initial;
    min-width: 63px;
  }
  .select-custom-filtros{
    min-width: 167px;
  }
  .select-custom-filtros::v-deep .vs__dropdown-toggle{
    border: 1px solid #7030A0;
    justify-content: left;
    align-items: center;
  }
  .select-custom-filtros::v-deep .vs__dropdown-option{
    font-size: 12px;
  }
  .select-custom-filtros::v-deep .vs__dropdown-menu{
    width: 273px;
  }
  .select-custom-filtros::v-deep .vs__selected-options{
    padding: 0;
    flex-basis: inherit;
    flex-grow: initial;
    padding-left: 5px;
    padding-top: 2px;
  }
  .select-custom-filtros::v-deep .vs__search{
    margin: 0;
    padding: 0;
    max-height: 0;
    max-width: 0;
  }
  .select-custom-filtros::v-deep .vs__selected{
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #7030A0 !important;
    padding-right: 3px;
  }
  .select-custom-filtros.vs--single.vs--open::v-deep .vs__selected{
    position: relative;
  }
  .select-custom-filtros::v-deep .feather-chevron-down{
    color: #7030A0;
  }
  .minimal-graph-size-pizza{
    height: 100%;
    width: 100%;
    max-height: 280px;
    max-width: 280px;
  }
  @media (min-width: 1600px) {
    .select-custom-filtros::v-deep .vs__selected{
      font-size: 13px;
    }
    .grouping-escalas{
      font-size: 14px;
    }
  }

  @media(max-width: 1280px){
    .grouping-escalas{
      font-size: 12px;
    }
    .item-escala{
      min-width: 53px;
    }

  }
  @media(min-width: 992px) and (max-width: 1280px){
      .espacamento-top{
        margin-top: 25px;
      }
  }
  @media(max-width: 905px){
    .grouping-escalas{
      font-size: 10px;
    }
    .item-escala{
      min-width: 45px;
    }

   
  }
  @media (max-width: 991px) {
    .select-custom-filtros::v-deep .vs__dropdown-option{
      font-size: 10px;
    }
    .select-custom-filtros::v-deep .vs__dropdown-menu{
      width: 200px;
    }
    .height-custom{
      height: 240px;
    }
  }
  </style>
  