<template>
  <b-col sm="12" class="altura">
    <span class="alinhar_centro"><h4>{{responseTypes[mensagemTipo].infoTextUpper}}<br>{{responseTypes[mensagemTipo].infoTextLower}}</h4><br></span>
  </b-col>
</template>

<script>
import { BCol } from 'bootstrap-vue'

export default {
  components: {
    BCol,
  },

  props: {
      mensagemTipo: {
          required: true,
          type: Number
      },
  },

    data() {
      return {
          responseTypes: [
              {
                  'case': 'insuficienteColaboradores',
                  'infoTextUpper': `Não há dados a serem exibidos.`,
                  'infoTextLower': `Ainda não há informações cadastradas suficientes para exibição desta informação.`
              },
              {
                  'case': 'erroDesconhecido',
                  'infoTextUpper': `Visualização indisponível no momento.`,
                  'infoTextLower': `Tente novamente mais tarde ou entre em contato com o suporte.`
              }
          ],
      }
  },

}
</script>
<style scoped>
.altura{
  display: table;
  height: 297px;
  width: 100%;
  text-align: center;
}
.alinhar_centro {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 1600px) {
  .altura{
    max-height:240px;
  }
}
@media (max-width: 1199px) {
  .altura{
    max-height:340px;
  }
}
@media (max-width: 420px) {
  .altura{
    max-height: 300px;
  }
}
@media (max-width: 380px) {
  .altura{
    max-height: 270px;
  }
}
@media (max-width: 350px) {
  .altura{
    max-height: 240px;
  }
}
</style>
