<template>
    <b-card class="text-center emptyCard py-3 px-1">
      <div class="mb-3 customTitle">
          {{ moduleLabel }}
      </div>
      <div class="mb-3 customSubtitle">
          Sua empresa ainda não iniciou um ciclo deste produto ou ainda não há dados o suficiente para exibir.
      </div>
      <div class="mb-3 customSubtitle">
          Entre em contato com o SESI para mais informações.
      </div>
    </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
    components: {
      BCard
    },

    props: {
      moduleLabel: {
        type: String,
        required: true
      },
    }
}
</script>
<style lang="scss">
    .emptyCard{
        border: 2px solid yellow;
    }
    .customTitle{
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        color: #5E5873;
    }
    .customSubtitle{
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #B9B9C3;
    }
</style>
