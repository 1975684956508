<template>
  <b-card v-if="data" no-body>
    <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
      <b-card-header>
        <b-card-title class="mb-0">
          Fase {{ this.FaseSelecionada.id }} - {{ situacaoFase }}
        </b-card-title>
      </b-card-header>
      <b-card-text class="ml-2 mb-0 mt-n1">
        {{ dataFaseInicio }} - {{ dataFaseFim }}
      </b-card-text>
      <vue-apex-charts
        type="radialBar"
        height="360"
        class="mb-1"
        :options="graficoGoalOverviewRadialBar.chartOptions"
        :series="graficoGoalOverviewRadialBar.series"
      />
      <b-row class="text-center mx-0">
        <b-col xl="3" lg="6" md="6" class="border-top border-right py-1">
          <p class="text-muted mb-0">Participantes</p>
          <p class="font-weight-bolder mb-0 fonte18">
            {{ this.FaseSelecionada.participantes }}
          </p>
        </b-col>

        <b-col
          xl="3"
          lg="6"
          md="6"
          class="border-top border-right_responsivo py-1"
        >
          <p class="text-muted mb-0">Recusas</p>
          <p class="font-weight-bolder mb-0 fonte18">
            {{ this.FaseSelecionada.recusas }}
          </p>
        </b-col>

        <b-col xl="3" lg="6" md="6" class="border-top border-right py-1">
          <p class="text-muted mb-0">Respostas</p>
          <p class="font-weight-bolder mb-0 fonte18">
            {{ this.FaseSelecionada.respostas }}
          </p>
        </b-col>

        <b-col xl="3" lg="6" md="6" class="border-top py-1">
          <p class="text-muted mb-0">Pendentes</p>
          <p class="font-weight-bolder mb-0 fonte18">
            {{ pendentesTotal }}
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BOverlay,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

const $strokeColor = "#ebe9f1";
const $textHeadingColor = "#5E5873";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BOverlay,
    BCardTitle,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    }
  },
  data() {
    return {
      loadingShow: false,
      pendentesTotal: 0,
      situacaoFase: "",
      dataFaseInicio: "",
      dataFaseFim: "",
      graficoGoalOverviewRadialBar: {
        series: [],
        chartOptions: {},
      },
    };
  },
  beforeMount() {
    this.populacaoCard();
  },
  methods: {
    calculoPorcentagemTooltip(valor, participantes) {
      let porcentagem = ((valor / participantes) * 100).toFixed(0);
      if (porcentagem == 0) {
        porcentagem = this.toFixed((valor / participantes) * 100, 1);
        if (porcentagem == 0.0) {
          porcentagem = ((valor / participantes) * 100).toFixed(0);
        }
      }
      return porcentagem;
    },
    toFixed(num, fixed) {
      let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    },
    formatarData(data) {
      const objetoData = this.parseDate(data);
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },
    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },
    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return "Não iniciado";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return "Em andamento";
      } else if (objetoDataFim < objetoDataNow) {
        return "Finalizado";
      }
    },
    chamadaGrafico() {
      return {
        chart: {
          height: 330,
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ["#34B313"],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "77%",
            },
            track: {
              background: $strokeColor,
              strokeWidth: "50%",
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: 35,
              },
              value: {
                offsetY: -10,
                color: $textHeadingColor,
                fontFamily: "Montserrat",
                fontSize: "4rem",
                fontWeight: "500",
              },
              total: {
                show: true,
                label: "Completo",
                fontSize: "1.5rem",
                fontFamily: undefined,
                fontWeight: 0,
                color: "#B9B9C3",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            type: "horizontal",
            gradientToColors: ["#2772BF"],
            inverseColors: false,
            stops: [0, 50],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            fontSize: "14px",
          },
          custom: function () {
            return (
              '<div style="margin:20px;">' +
              "<div> 0% Recusas </div>" +
              "<div> 0% Respostas </div>" +
              "</div>"
            );
          },
        },
      };
    },
    populacaoCard() {
      this.pendentesTotal =
        this.FaseSelecionada.participantes -
        (this.FaseSelecionada.respostas + this.FaseSelecionada.recusas);
      if (this.pendentesTotal < 0) {
        this.pendentesTotal = 0;
      }

      this.situacaoFase = this.progressoFase(
        this.FaseSelecionada.inicio,
        this.FaseSelecionada.termino
      );
      this.dataFaseInicio = this.formatarData(this.FaseSelecionada.inicio);
      this.dataFaseFim = this.formatarData(this.FaseSelecionada.termino);
      let porcentagemRecusas = this.calculoPorcentagemTooltip(
        this.FaseSelecionada.recusas,
        this.FaseSelecionada.participantes
      );
      let porcentagemRespostas = this.calculoPorcentagemTooltip(
        this.FaseSelecionada.respostas,
        this.FaseSelecionada.participantes
      );
      this.graficoGoalOverviewRadialBar.chartOptions = this.chamadaGrafico();

      this.graficoGoalOverviewRadialBar.series = [
        (
          ((this.FaseSelecionada.respostas + this.FaseSelecionada.recusas) /
            this.FaseSelecionada.participantes) *
          100
        ).toFixed(0),
      ];

      this.graficoGoalOverviewRadialBar.chartOptions.tooltip.custom = [
        function () {
          return (
            '<div style="margin:20px;">' +
            "<div>" +
            porcentagemRecusas +
            "% Recusas </div>" +
            "<div>" +
            porcentagemRespostas +
            "% Respostas </div>" +
            "</div>"
          );
        },
      ];
      this.loadingShow = true;
    },
  },
  watch: {
    FaseSelecionada: function () {
      this.populacaoCard();
    },
  },
};
</script>
<style scoped>
.border-right_responsivo{
  border-right:1px solid #efefef;
}
@media (max-width: 1710px) {
  .col-xl-3{
    flex:0 0 50%;
    max-width:50%;
  }
  .border-right_responsivo{
    border-right:0px;
  }
}
</style>
