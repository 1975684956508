<template>
    <b-row
        class="justify-content-center mb-1  legenda "
    >
        <div class="mb-1 legenda-items  espacamento">
            <div class="legenda_item bola dias"></div> 
            <span class="legenda_item">Em 30 dias</span>
        </div>
        <div class="mb-1 legenda-items espacamento ">
            <div class="legenda_item bola meses"></div>
            <span class="legenda_item">Em 6 meses</span>
        </div>
        <div class="mb-1  legenda-items espacamento">
            <div class="legenda_item bola algumdia"></div>
            <span class="legenda_item">Algum dia</span>
        </div>
        <div class="mb-1 legenda-items espacamento">
            <div class="legenda_item bola semmud "></div>
            <span class="legenda_item">Sem mudanças</span>
        </div>
        <div class="mb-1 legenda-items espacamento">
            <div class="legenda_item bola naopre"></div>
            <span class="legenda_item">Não preciso de mudanças</span>
        </div>
    </b-row>
</template>

<script>

import {
    BRow,
    BCol,
} from "bootstrap-vue";

export default {
    components: {
        BRow,
        BCol,
    },
    name: "LegendaMudancaHabitos"
}
</script>

<style scoped>
   .legenda{
      margin-left: 0px;
  }
  .espacamento{
      margin-right: 2rem;
  }
  .legenda-items{
    display: flex;
    align-items: center;
    justify-content: center
  }
  .legenda_item{
      display: inline-block;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-size: 13px;
      margin-left: 10px;
  }
  
.bola{
    width: 12px;
    height: 12px;
    border-radius: 12px;
    min-width: 12px;
}

  .dias {
    background: #36BA00;
  }
  .meses {
  background: #2772C0;
  }
  .algumdia {
  background: #002060;
  }
  
  .semmud {
  background: #7030A0;
  }
  
  .naopre {
  background: #AA228C;
  }

@media (min-width: 992px) and (max-width: 1392px) {
    .legenda_item{
        font-size: 10px ;
        margin-left: 4px;
    }

    .espacamento{
        margin-right: 1rem;
    }
    
}

@media (min-width: 1392px) and (max-width: 1590px) {
    .legenda_item{
        font-size: 13px ;
        margin-left: 4px;
    }

    .espacamento{
        margin-right: 1rem !important;
    }
}
@media (max-width: 440px) {
    .legenda_item{
        font-size: 10px ;
        margin-left: 4px;
    }

    .espacamento{
        margin-right: 1rem !important;
    }
}
</style>