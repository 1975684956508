<template>
  <b-card class="espacamento_titulo_grafico">
    <div v-show="!CardComErro">
      <b-overlay
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="altura_grafico"
      >
        <vue-apex-charts
          v-if="mostraGrafico"
          height="250"
          type="bar"
          :options="chartOptions"
          :series="chartOptions.series"
        />
      </b-overlay>
    </div>
    <div v-show="CardComErro">
      <card-sem-dados :mensagemTipo="0"> </card-sem-dados>
    </div>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import CardSemDados from "@/views/pages/dashboard/CardSemDados.vue";

export default {
  components: {
    VueApexCharts,
    BCard,
    BOverlay,
    CardSemDados,
  },
  props: {
    DoencaSelecionada: {
      type: String,
      default: "Diabetes",
      required: true,
    },
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  mounted() {
    this.buscaDadosCard();
  },
  methods: {
    buscaDadosCard() {
      this.loadingShow = true;
      this.mostraGrafico = false;
      this.chartOptions.colors = [];
      this.chartOptions.xaxis.categories = [];
      this.chartOptions.series[0].data = [];

      let params = {
        idEmpresa: this.FaseSelecionada.id_empresa,
        idFase: this.FaseSelecionada.id_fase,
        nomeDoenca: this.DoencaSelecionada
      };

      this.$http
        .get(this.$api.DashboardPlanejaDoencasCronicas, { params })
        .then((res) => {
          if (res.status == 204 || res.data == null) {
            this.CardComErro = true;
          } else {
            this.CardComErro = false;

            this.dadosParaOComponenteFuncionar = this.ordenarRetorno(res.data);

            for (const element of this.dadosParaOComponenteFuncionar) {
              this.chartOptions.series[0].data.push(element["totalizador"]);

              this.chartOptions.colors.push(
                this.aliasCores(element["classificacao"])
              );
              this.chartOptions.xaxis.categories.push(element["classificacao"]);
            }
            this.chartOptions.xaxis.title.text = this.DoencaSelecionada;
          }
          this.mostraGrafico = true;
          this.loadingShow = false;
        })
        .catch((_error) => {
          this.mostraGrafico = true;
          this.loadingShow = false;
          this.CardComErro = true;
        });
    },

    compare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    ordenarRetorno(dados) {
      for (let element of dados) {
        switch (element["classificacao"]) {
          case "Faz uso medicação":
            element["order"] = "1";
            break;
          case "Não faz uso medicação":
            element["order"] = "2";
            break;
          case "Não precisa de medicação":
            element["order"] = "3";
            break;
          case "Não sabe se possui a doença":
            element["order"] = "4";
            break;
          case "Não possui a doença":
            element["order"] = "5";
            break;
        }
      }
      return dados.sort(this.compare);
    },
    aliasCores(escala) {
      switch (escala) {
        case "Faz uso medicação":
          escala = "#36BA00";
          break;
        case "Não faz uso medicação":
          escala = "#2772C0";
          break;
        case "Não precisa de medicação":
          escala = "#002060";
          break;
        case "Não sabe se possui a doença":
          escala = "#7030A0";
          break;
        case "Não possui a doença":
          escala = "#AA228C";
          break;
      }

      return escala;
    },
  },
  data() {
    return {
      mostraGrafico: false,
      loadingShow: true,
      dadosParaOComponenteFuncionar: [],
      CardComErro: false,

      chartOptions: {
        series: [
          {
            name: "Respostas",
            data: [],
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
        },
        colors: [],

        plotOptions: {
          bar: {
            columnWidth: "20%",
            distributed: true,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return Math.floor(val);
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
            style: {
              fontSize: "12px",
            },
          },
          title: {
            text: this.DoencaSelecionada,

            style: {
              color: "#B9B9C3",
              fontWeight: "400",
              fontFamily: "Montserrat",
              fontStyle: "normal",
            },
          },
        },
      },
    };
  },

  watch: {
    FaseSelecionada: function () {
      this.buscaDadosCard();
    },

    DoencaSelecionada: function () {
      this.buscaDadosCard();
    },
  },
};
</script>
<style scoped>
.altura_grafico{
  max-height: 0;
}
.espacamento_titulo_grafico{
  margin-top: 40px;
}
@media (max-width: 1600px) {
  .espacamento_titulo_grafico{
    margin-top: 50px;
  }
}
@media (max-width: 1440px) {
  .espacamento_titulo_grafico{
    margin-top: 50px;
  }
}
@media (max-width: 1300px) {
  .espacamento_titulo_grafico{
    margin-top: 70px;
  }
}
@media (max-width: 1199px) {
  .espacamento_titulo_grafico{
    margin-top: 0px;
  }
  .altura_grafico{
    max-height: 340px;
  }
}
@media (max-width: 420px) {
  .altura_grafico{
    max-height: 300px;
  }
}
@media (max-width: 380px) {
  .altura_grafico{
    max-height: 270px;
  }
}
@media (max-width: 350px) {
  .altura_grafico{
    max-height: 240px;
  }
}
</style>
