<template>
  <div>
    <div v-if="dados && dados.length > 0">
      <div class="p-1">
        <b-overlay :show="loadingShow" rounded="lg" opacity="0.6"/>
          <b-col class="mb-1">
            <b-row
              class=" mb-2 legend-container no-gutters"
              cols-sm="3"
              cols-md="3"
            >
              <b-col  class="legenda-item">
                <div class="legenda bola bom"></div>
                <span class="legenda">Bom</span>
              </b-col>
              <b-col  class="legenda-item">
                <div class="legenda bola reg"></div>
                <span class="legenda">Atenção</span>
              </b-col>
              <b-col class="legenda-item">
                <div class="legenda bola ruim"></div>
                <span class="legenda">Precisa melhorar</span>
              </b-col>
            </b-row>

            <div class="over">
              <div
                class="mb-1 pb-1 linha"
                v-for="(item, item_id) in dadosParaOComponenteFuncionar"
                :key="item_id"
              >
                <b-row>
                  <b-col lg="4" md="4">
                    <div class="tituloClassificacaoes">{{ item.indice }}</div>
                  </b-col>
                  <b-col lg="8" md="8">
                    <b-progress
                      :max="item.max"
                      class="rounded-0 clicavel"
                      :id="'tooltip-target-progress' + item_id"
                      height="1.0rem"
                      style="margin-top: 5px"
                    >
                      <b-progress-bar
                        v-for="(valor, valor_id) in item.valores
                          .slice()
                          .reverse()"
                        :key="valor_id"
                        :value="valor.totalizador"
                        :class="valor.classe"
                        :id="'tooltip-target-bar' + valor_id"
                      >
                      </b-progress-bar>
                      <b-tooltip
                        :target="'tooltip-target-progress' + item_id"
                        triggers="hover"
                        variant="light"
                        v-if="item.Classificacao == 'Demais Classificacaoes'"
                      >
                      </b-tooltip>
                      <b-tooltip
                        :target="'tooltip-target-progress' + item_id"
                        triggers="hover"
                        variant="light"
                        v-else
                      >
                        <div class="text-left">
                          <strong class="fonte14">{{
                            item.Classificacao
                          }}</strong>
                          <ul class="lista">
                            <li
                              v-for="(
                                tooltipValor, tooltipValor_id
                              ) in item.valores.slice().reverse()"
                              :key="tooltipValor_id"
                              class="alturaLinha"
                            >
                              <span
                                class="bullet"
                                :class="tooltipValor.classe"
                              ></span>
                              <span>
                                {{ tooltipValor.classificacao }}:<strong>
                                  {{ tooltipValor.totalizador }}</strong
                                ></span
                              >
                            </li>
                          </ul>
                        </div>
                      </b-tooltip>
                    </b-progress>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
      </div>
    </div>
    <div
        v-else
        class="height-custom d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
  </div>
</template>

<script>
import {
  BProgress,
  BProgressBar,
  BRow,
  BCol,
  BTooltip,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CardSemDados from "./CardSemDados.vue";

export default {
  components: {
    BProgress,
    BProgressBar,
    BRow,
    BCol,
    BTooltip,
    VuePerfectScrollbar,
    BOverlay,
    CardSemDados,
  },
  props: {
    DataCicloTempoReal: {
      type: String,
      default() {
        return {};
      },
      required: true,
    },
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    dados:{
      type: Array,
      default() {
        return [];
      },
      required: true,
    }
  },
  data() {
    return {
      loadingShow: true,
      dadosParaOComponenteFuncionar: [],
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.buscaDadosCardclassificacaoRespostasClassificacao();
  },
  methods: {
    buscaDadosCardclassificacaoRespostasClassificacao() {
      this.validarCardSemDados.verificadorVazio = false;
      let arrayDoBanco = this.$props.dados;
           
      this.dadosParaOComponenteFuncionar =
      this.criarArrayDadosGrafico(arrayDoBanco);
        
      this.loadingShow = false; 
    },
    criarArrayDadosGrafico(arrayDoBanco) {
      let arrayFormatacao = [];

      for (const element of arrayDoBanco) {
        if (element["classificacao"] != null) {
          this.popularArray(arrayFormatacao, element);
        }
      }
      arrayFormatacao = this.criarPropMax(arrayFormatacao);
      return arrayFormatacao;
    },
    popularArray(arrayFormatacao, arrayBancoAtual) {
      if (
        !this.validadorExistenciaPrevia(
          arrayFormatacao,
          arrayBancoAtual["indice"]
        )
      ) {
        arrayFormatacao.push({
          indice: arrayBancoAtual["indice"],
          valores: [
            {
              totalizador: arrayBancoAtual["totalizador"],
              classificacao: this.aliasclassificacao(
                arrayBancoAtual["classificacao"]
              ),
              classe: this.aliasCor(arrayBancoAtual["classificacao"]),
            },
          ],
        });
      } else {
        for (const element of arrayFormatacao) {
          if (element["indice"] == arrayBancoAtual["indice"]) {
            element["valores"].push({
              totalizador: arrayBancoAtual["totalizador"],
              classificacao: arrayBancoAtual["classificacao"],
              classe: this.aliasCor(arrayBancoAtual["classificacao"]),
            });
          }
        }
      }
    },
    validadorExistenciaPrevia(arrayDados, idEmpresaClassificacao) {
      return arrayDados.some((el) => el.indice === idEmpresaClassificacao);
    },
    aliasclassificacao(classificacao) {
      switch (classificacao) {
        case 0:
          classificacao = "Bom";
          break;
        case 1:
          classificacao = "Atenção";
          break;
        case 2:
          classificacao = "Vamos melhorar?";
          break;
      }
      return classificacao;
    },
    aliasCor(classe) {
      switch (classe) {
        case "Bom":
          classe = "cor-classificacao-zero";
          break;
        case "Atenção":
          classe = "cor-classificacao-um";
          break;
        case "Vamos Melhorar?":
          classe = "cor-classificacao-quatro";
          break;
      }
      return classe;
    },
    criarPropMax(arrayDadosFormatados) {
      for (const element of arrayDadosFormatados) {
        let sum = 0;
        for (const aux of element["valores"]) {
          sum += aux["totalizador"];
        }
        element.max = sum;
      }
      return arrayDadosFormatados;
    },
  },
  watch: {
    FaseSelecionada: function () {
      this.buscaDadosCardclassificacaoRespostasClassificacao();
    }
  }
};
</script>

<style scoped>
.height-custom{
  height: 240px;
}
.lista{
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size:13px;
  white-space:nowrap;
  width:100%;
  margin-right: 10px;
}
.legend-container{
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.legenda-item{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 230px;
}
.alturaLinha{
  height:20px;
}

.legenda{
    display: inline-block;
    margin-left: 10px;
    min-width: 12px;

}
.over{
  overflow: hidden;
  max-height: 10%;
}

.bola{
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

.bom {
background: #36BA00;
}
.reg {
background: #2772C0;
}
.ruim {
background: #AA228C;
}
.cor-classificacao-zero{
  background-color: #36BA00;
}
.cor-classificacao-um{
  background-color: #2772C0;
}
.cor-classificacao-dois{
  background-color: #002060;
}
.cor-classificacao-tres{
  background-color: #7030A0;
}
.cor-classificacao-quatro{
  background-color: #AA228C;
}
.style-chooser #vs1__combobox {
  height:50px;
  width: 100%;
  font-size: 14px;
}
.bullet{
  margin-right: 8%;
}

@media (max-width: 1600px) {
  .style-chooser {
    margin: 0 -20px;
  }
}
@media (max-width: 1440px) {
  .style-chooser {
    margin: 0 -30px;
  }
}


@media (max-width: 1199px) {
  
  .style-chooser #vs1__combobox {
    height:50px;
    width: 100%;
    font-size: 14px;
  }
  .bullet{
    margin-right: 8%;
  }
}
</style>
