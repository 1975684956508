<template>
    <vue-apex-charts
      v-if="mostraGrafico"
      height="300"
      ref="myChart"
      :width="width"
      type="bar"
      :options="chartOptions"
      :series="chartOptions.series"
    />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    rotulo: {
      type: String,
      required: true,
      default: "",
    },

    dados: {
      type: Array,
      required: true,
    }
  },
  mounted() {
    this.buscaDadosCard();
  },
  created() {
    this.reDim();
    window.addEventListener("resize", this.reSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.reSize);
  },

  methods: {
    reDim() {
      this.chartOptions = {
        ...this.chartOptions,
        ...{
          yaxis: {
            labels: {
              show: true,
              formatter: function (val) {
                return Math.floor(val);
              },
            },
          },
        },
      };
    },
    reSize() {
      this.reDim();
    },
    verificaLimiteMaximoGrafico(value) {
      this.limiteMaximoGrafico =
        value > this.limiteMaximoGrafico ? value : this.limiteMaximoGrafico;
      this.reDim();
    },
    buscaDadosCard() {
      this.loadingShow = true;
      this.chartOptions.xaxis.categories = [];
      this.chartOptions.series[0].data = [];

          this.dadosParaOComponenteFuncionar = this.ordenarRetorno(this.dados);    

          for (const element of this.dadosParaOComponenteFuncionar) {
            this.chartOptions.series[0].data.push(element["totalizador"]);
            this.verificaLimiteMaximoGrafico(element["totalizador"]);
            this.chartOptions.colors.push(
              this.aliasCores(element["classificacao"])
            );
            this.chartOptions.xaxis.categories.push(element["classificacao"]);
          }

        this.mostraGrafico = true;   
    },

    compare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },
    ordenarRetorno(dados) {
      for (let element of dados) {
        switch (element["classificacao"]) {
          case "Em 30 dias":
            element["order"] = "1";
            break;
          case "Em 6 meses":
            element["order"] = "2";
            break;
          case "Algum dia":
            element["order"] = "3";
            break;
          case "Sem mudanças":
            element["order"] = "4";
            break;
          case "Não preciso de mudanças":
            element["order"] = "5";
            break;
        }
      }
      return dados.sort(this.compare);
    },
    aliasCores(escala) {
      switch (escala) {
        case "Sem mudanças":
          escala = "#7030A0";
          break;
        case "Em 30 dias":
          escala = "#36BA00";
          break;
        case "Em 6 meses":
          escala = "#2772C0";
          break;
        case "Algum dia":
          escala = "#002060";
          break;
        case "Não preciso de mudanças":
          escala = "#AA228C";
          break;
      }

      return escala;
    },
  },
  data() {
    return {
      loadingShow: false,
      mostraGrafico: false,
      width: "100%",
      limiteMaximoGrafico: 1,
      CardComErro: false,
      dadosParaOComponenteFuncionar: [],

      chartOptions: {
        series: [
          {
            name: "Respostas",
            data: [],
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
          lines: {
            show: true,
          },

          height: 350,
          type: "bar",
        },
        colors: [],

        plotOptions: {
          bar: {
            columnWidth: "20%",
            distributed: true,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: true,

          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 30,
          },
        },

        yaxis: {
          labels: {
            offsetX: -15,
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          categories: [],
          labels: {
            show: false,
            style: {
              fontSize: "12px",
            },
          },
          title: {
            text: this.rotulo,

            style: {
              color: "#B9B9C3",
              fontWeight: "400",
              fontFamily: "Montserrat",
              fontStyle: "normal",
            },
          },
        },
      },
    };
  },

  watch: {
    FaseSelecionada: function () {
      this.series = [];
      this.buscaDadosCard();
    },
  },
};
</script>
<style scoped>
.espacamento_titulo_grafico{
  margin-top: 40px;
}
@media (max-width: 1600px) {
  .espacamento_titulo_grafico{
    margin-top: 50px;
  }
}
@media (max-width: 1440px) {
  .espacamento_titulo_grafico{
    margin-top: 50px;
  }
}
@media (max-width: 1300px) {
  .espacamento_titulo_grafico{
    margin-top: 70px;
  }
}
@media (max-width: 1199px) {
  .espacamento_titulo_grafico{
    margin-top: 0px;
  }
  .altura_grafico{
    max-height: 340px;
    max-width: 270px;

  }
}
@media (max-width: 420px) {
  .altura_grafico{
    max-height: 300px;
    max-width: 270px;

  }
}
@media (max-width: 380px) {
  .altura_grafico{
    max-height: 270px;
    max-width: 270px;

  }
}
@media (max-width: 350px) {
  .altura_grafico{
    max-height: 240px;
    max-width: 270px;

  }
}
</style>


