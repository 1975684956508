import { render, staticRenderFns } from "./TaxaRespostaEquipe.vue?vue&type=template&id=085b7ac9&scoped=true&"
import script from "./TaxaRespostaEquipe.vue?vue&type=script&lang=js&"
export * from "./TaxaRespostaEquipe.vue?vue&type=script&lang=js&"
import style0 from "./TaxaRespostaEquipe.vue?vue&type=style&index=0&id=085b7ac9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085b7ac9",
  null
  
)

export default component.exports