<template>
    <section :class="{'p-2': dados.length == 0} ">
        <div v-if="dados && dados.length > 0">
            <b-overlay :show="loadingShow" rounded="lg" opacity="1" />
            <b-row
                cols="2"
                cols-lg="5"
                cols-sm="1" 
                cols-md="2"
            >
                <b-col class="mb-1">
                    <div id="cardProd" class="cardsSup">
                    <div class="cardTitle">Produtividade</div>
        
                    <div class="cardVIdeal">Valor ideal: 80</div>
        
                    <div class="cardValor">
                        {{ Math.floor(dadosCards.Produtividade.percentual) }}
                    </div>
        
                    <b-progress
                        variant="white"
                        class="cardBarra"
                        :value="Math.floor(dadosCards.Produtividade.percentual)"
                        max="100"
                    />
                    <b-img
                        class="cardImg"
                        :src="require('@/assets/images/dashboard/produtividade.svg')"
                    />
                    </div>
                </b-col>
                <b-col class="mb-1">
                    <div id="cardAli" class="cardsSup">
                    <div class="cardTitle">Alimentação</div>
        
                    <div class="cardVIdeal">Valor ideal: 66</div>
        
                    <div class="cardValor">
                        {{ Math.floor(dadosCards.Alimentação.percentual) }}
                    </div>
        
                    <b-progress
                        variant="white"
                        class="cardBarra"
                        :value="Math.floor(dadosCards.Alimentação.percentual)"
                        max="100"
                    />
                    <b-img
                        class="cardImg"
                        :src="require('@/assets/images/dashboard/alimentacao.svg')"
                    />
                    </div>
                </b-col>
                <b-col class="mb-1">
                    <div id="cardEstilo" class="cardsSup">
                    <div class="cardTitle">Estilo de vida</div>
        
                    <div class="cardVIdeal">Valor ideal: 80</div>
        
                    <div class="cardValor">
                        {{ Math.floor(dadosCards["Estilo de Vida"].percentual) }}
                    </div>
        
                    <b-progress
                        variant="white"
                        class="cardBarra"
                        :value="Math.floor(dadosCards['Estilo de Vida'].percentual)"
                        max="100"
                    />
                    <b-img
                        class="cardImg"
                        :src="require('@/assets/images/dashboard/estilo.svg')"
                    />
                    </div>
                </b-col>
        
                <b-col class="mb-1">
                    <div id="cardAtiv" class="cardsSup">
                    <div class="cardTitle">Atividade Física</div>
        
                    <div class="cardVIdeal">Valor ideal: 66</div>
        
                    <div class="cardValor">
                        {{ Math.floor(dadosCards["Atividade Física"].percentual) }}
                    </div>
        
                    <b-progress
                        variant="white"
                        class="cardBarra"
                        :value="Math.floor(dadosCards['Atividade Física'].percentual)"
                        max="100"
                    />
                    <b-img
                        class="cardImg"
                        :src="require('@/assets/images/dashboard/atividade.svg')"
                    />
                    </div>
                </b-col>
        
                <b-col class="mb-1">
                    <div id="cardEstr" class="cardsSup">
                    <div class="cardTitle">Estresse</div>
        
                    <div class="cardVIdeal">Valor ideal: 66</div>
        
                    <div class="cardValor">
                        {{ Math.floor(dadosCards["Estresse"].percentual) }}
                    </div>
        
                    <b-progress
                        variant="white"
                        class="cardBarra"
                        :value="Math.floor(dadosCards.Estresse.percentual)"
                        max="100"
                    />
                    <b-img
                        class="cardImg"
                        :src="require('@/assets/images/dashboard/estresse.svg')"
                    />
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-else>
          <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro">
          </card-sem-dados>
        </div>

       
    </section>
  </template>
  
  <script>
    import { BRow, BCol, BOverlay, BProgress, BImg } from "bootstrap-vue";
    import CardSemDados from "./CardSemDados.vue";
  
  export default {
    components: {
      BRow,
      BCol,
      BOverlay,
      BProgress,
      BImg,
      CardSemDados,
    },
    props: {
      FaseSelecionada: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
      dados: {
        type: Array,
        default() {
          return [];
        },
        required: true,
      }
    },
    data() {
      return {
        loadingShow: true,
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0,
        },
        dadosCards: {
          Produtividade: { percentual: 0 },
          Alimentação: { percentual: 0 },
          "Atividade Física": { percentual: 0 },
          "Estilo de Vida": { percentual: 0 },
          Estresse: { percentual: 0 },
        },
      };
    },
    mounted() {
      this.buscaDados();
    },
    methods: {
      formataPercent(valor) {
        return Math.floor(valor);
      },
      localizaDados(valor, array, campo) {
        return array.filter((obj) => {
          return obj[campo] == valor;
        })[0];
      },
      criacaoPreenchimentoCards(dados) {
        dados.map((item) => {
          this.dadosCards[item.indice] = this.localizaDados(
            item.indice,
            dados,
            "indice"
          );
        });
      },
      buscaDados() {
        this.dadosCards = this.$props.dados;
        this.criacaoPreenchimentoCards(this.dadosCards);
        this.loadingShow = false;
    }
    },
      watch: {
          FaseSelecionada: function () {
              this.buscaDados();
          }
      }
  };
  </script>
  
  <style lang="scss">
  #cardProd {
    background: linear-gradient(45deg, #7030a0 0%, #a369ce 100%);
    order: 0;
    z-index: -2;
  }
  #cardAli {
    background: linear-gradient(45deg, #2772c0 0%, #69b3ff 100%);
    order: 1;
    z-index: -2;
  }
  #cardEstilo {
    background: linear-gradient(171.57deg, #054d98 29.03%, #002060 70.83%);
    order: 2;
    z-index: -2;
  }
  #cardAtiv {
    background: linear-gradient(45.79deg, #56993b 0%, #8bdb6b 94.75%);
    order: 3;
    z-index: -2;
  }
  #cardEstr {
    background: linear-gradient(154.93deg, #be5ca9 17.96%, #76196d 81.75%);
    order: 4;
    z-index: -2;
  }
  .cardBarra {
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    width: 90%;
    height: 7px;
    left: 10px;
    bottom: 10px;
    box-sizing: border-box;
    z-index: 2;
  }
  .cardVIdeal {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    z-index: 2;
  }
  .cardValor {
    width: 100%;
    height: 49px;
    text-align: center;
    top: 58px;
    z-index: 2;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
  }
  .cardTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    z-index: 2;
  }
  .cardsSup {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    margin-bottom: 15px;
    width: 100%;
    height: 131px;
    border-radius: 6px;
    flex-grow: 0;
    color: white;
    padding: 10px;
    position: relative;
  }
  .cardImg {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: -1;
  }
  </style>
  