<template>
  <vue-apex-charts
    v-if="mostraGrafico"
    id="chart"
    ref="myChart"
    type="bar"
    height="250"
    width="100%"
    :options="chartOptions"
    :series="chartOptions.series"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  props: {
    dados: {
      type: Object,
      required: true
    },
    descricao: {
      type: String,
      required: true
    }
  },

  mounted() {
    this.adapataDados();
    this.chartOptions.xaxis.categories.push(this.descricao);
  },

  methods: {
    adapataDados() {
      this.dadosGrafico = this.ordenarRetorno(this.$props.dados.dados)
      this.chartOptions.colors = [];
      this.dadosGrafico.forEach(element => {
          this.chartOptions.series[0].data.push(element.totalizador);
          this.cores.push(
            this.aliasCores(element.classificacao)
          );
      })

      this.chartOptions.colors = this.cores
      this.mostraGrafico = true;

    },

    compare(a, b) {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    },

    ordenarRetorno(dados) {
      for (let element of dados) {
        switch (element.classificacao) {
          case "Faz uso medicação":
            element.order = "1";
            break;
          case "Não faz uso medicação":
            element.order = "2";
            break;
          case "Não precisa de medicação":
            element.order = "3";
            break;
          case "Não sabe se possui a doença":
            element.order = "4";
            break;
          case "Não possui a doença":
            element.order = "5";
            break;
        }
      }
      return dados.sort(this.compare);
    },

      aliasCores(escala) {
          switch (escala) {
              case "Faz uso medicação":
              escala = "#36BA00";
              break;
              case "Não faz uso medicação":
              escala = "#2772C0";
              break;
              case "Não precisa de medicação":
              escala = "#002060";
              break;
              case "Não sabe se possui a doença":
              escala = "#7030A0";
              break;
              case "Não possui a doença":
              escala = "#AA228C";
              break;
          }

          return escala;
      },

  },
  data() {
    return {
      series: [],
      cores: [],
      dadosGrafico: [],
      mostraGrafico: false,
      chartOptions: {
        series: [
          {
            name: "Respostas",
            data: [],
          },
        ],
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
          height: 250,
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: '400'
        },
        plotOptions: { 
          bar: {
            horizontal: false,
            endingShape: 'rounded',
            columnWidth: '40%',
            distributed: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 7,
          colors: ['transparent']
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return Math.floor(val);
            },
          },
        },
        xaxis: {
          categories: [],
          crosshairs: {
            show: false
          },
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            trim: true,
            style: {
              colors: '#B9B9C3',
              fontSize: "12px"
            },
          },
          axisTicks: {
            show: false
          },
        },
        legend: {
          show: false
        },
        
     
        responsive:[
          {
            breakpoint:991,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: "10px"
                  },
                }
              }
            }
          }
        ]
      },
    };
  }
};
</script>
<style scoped>
.tooltip-teste{
  background-color: #82868B;
  font-size: 0.857rem;
  font-weight: 400;
  line-height: 1.45;
  color: white;
  padding: 5px 10px;
}

.height-custom{
  height:0 !important;
}

.ps--active-x>.ps__rail-x, .ps--active-y>.ps__rail-y{
  display: none !important;
}
</style>

