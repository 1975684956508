<template>
<div>
    <div v-if="dados && dados.length > 0">
        <div class="card-employee-task">
            <b-overlay
                v-show="loadingShow"
                :show="loadingShow"
                rounded="lg"
                opacity="0.6"

            />
            <b-card-header>
                <b-card-title> Taxa de Respostas por Equipe </b-card-title>
                <b-row class="ordenacao">
                    <span>Ordenar por: </span>
                    <b-button
                    class="alfaBtn"
                    variant="outline-secondary"
                    hover
                    title="Ordem alfabética"
                    @click="ordenarAlfa(!tipoOrdemAlfa)"
                    >
                    <span v-if="tipoOrdemAlfa">A > Z</span>
                    <span v-else>A < Z</span>
                    </b-button>
                    <b-button
                    class="percentBtn"
                    variant="outline-secondary"
                    hover
                    title="Ordenar por porcentagem"
                    @click="ordenarPorcentAsc()"
                    >
                    <feather-icon icon="ChevronsDownIcon" />
                    </b-button>
                    <b-button
                    class="percentBtn"
                    variant="outline-secondary"
                    hover
                    title="Ordenar por porcentagem"
                    @click="ordenarPorcentDesc()"
                    >
                    <feather-icon icon="ChevronsUpIcon" />
                    </b-button>
                </b-row>
            </b-card-header>
            <b-card-body>
                <vue-perfect-scrollbar class="scroll-area" :settings="settings">
                    <div
                        v-for="(funcionario, id) in dadosParaOComponenteFuncionar"
                        :key="id"
                        class="
                            employee-task
                            d-flex
                            justify-content-between
                            align-items-center
                            mr-2
                        "
                    >
                        <b-media no-body>
                            <b-media-aside class="mr-75">
                            <b-avatar
                                class="rounded-circle"
                                size="40"
                                :src="funcionario.avatar"
                            />
                            </b-media-aside>
                            <b-media-body class="my-auto">
                            <h6 class="mb-0">
                                {{ funcionario.nome }}
                            </h6>
                            <small>{{ funcionario.setor }}</small>
                            </b-media-body>
                        </b-media>

                        <div class="d-flex align-items-center">
                            <small class="text-muted mr-50"
                            >{{
                                porcentagemPorDoisSemNulo(
                                funcionario.respostas,
                                funcionario.liderados
                                )
                            }}%</small
                            >
                            <vue-apex-charts
                            type="radialBar"
                            height="30"
                            width="30"
                            :options="chartData[id].options ? chartData[id].options : {}"
                            :series="chartData[id].series ? chartData[id].series : {}"
                            />
                        </div>
                    </div>
                </vue-perfect-scrollbar>
            </b-card-body>
        </div>
    </div>
    <div
        v-else
        class="height-custom d-flex align-items-center justify-content-center"
      >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>
</div>

  </template>
  
  <script>
  
  import {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BButton,
    BRow,
  } from "bootstrap-vue";
  import VueApexCharts from "vue-apexcharts";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import CardSemDados from "./CardSemDados.vue";
  
  const $trackBgColor = "#e9ecef";
  const $fillColor = "#7030a0";
  export default {
    components: {
      BCard,
      BCardTitle,
      BCardHeader,
      BCardBody,
      BMedia,
      BMediaAside,
      BMediaBody,
      BAvatar,
      VueApexCharts,
      VuePerfectScrollbar,
      BOverlay,
      CardSemDados,
      BButton,
      BRow,
    },
    props: {
      FaseSelecionada: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
      dados: {
        type: Array,
        default() {
          return [];
        },
        required: true,
      }
    },
    data() {
      return {
        validarCardSemDados: {
          verificadorVazio: false,
          tipagemErro: 0,
        },
        loadingShow: true,
        tipoOrdemAlfa: true,
        dadosParaOComponenteFuncionar: [],
        settings: {
          maxScrollbarLength: 70,
          wheelSpeed: 0.3,
          wheelPropagation: false,
        },
        chartData: [],
        chart: {
          series: [0],
          options: {
            grid: {
              show: false,
              padding: {
                left: -15,
                right: -15,
                top: -12,
                bottom: -15,
              },
            },
            colors: [$fillColor],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "22%",
                },
                track: {
                  background: $trackBgColor,
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  },
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
          },
        },
      };
    },
  
    mounted() {
      this.buscaDadosCardTaxaRespostaEquipe();
    },
  
    methods: {
      buscaDadosCardTaxaRespostaEquipe() {
        this.dadosParaOComponenteFuncionar = this.$props.dados;
        this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
  
        this.ordenarAlfa(true);
           
        this.loadingShow = false; 
      },
      adicionarCampoPoercent() {
        this.dadosParaOComponenteFuncionar.map((item, index) => {
          this.dadosParaOComponenteFuncionar[index].percent =
            this.porcentagemPorDoisSemNulo(item.respostas, item.liderados);
        });
      },
  
      ordenarAlfa(asc) {
        this.tipoOrdemAlfa = asc;
        this.adicionarCampoPoercent();
        Promise.all(
          this.dadosParaOComponenteFuncionar.sort((a, b) => {
            if (asc) {
              if (a.nome < b.nome) return -1;
              if (a.nome > b.nome) return 1;
            } else {
              if (a.nome > b.nome) return -1;
              if (a.nome < b.nome) return 1;
            }
            return 0;
          })
        ).then(() => {
          this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
        });
      },
      ordenarPorcentDesc() {
        this.adicionarCampoPoercent();
        Promise.all(
          this.dadosParaOComponenteFuncionar.sort((a, b) => {
            if (parseInt(a.percent) > parseInt(b.percent)) return -1;
            if (parseInt(a.percent) < parseInt(b.percent)) return 1;
            return 0;
          })
        ).then(() => {
          this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
        });
      },
      ordenarPorcentAsc() {
        this.adicionarCampoPoercent();
        Promise.all(
          this.dadosParaOComponenteFuncionar.sort((a, b) => {
            if (parseInt(a.percent) < parseInt(b.percent)) return -1;
            if (parseInt(a.percent) > parseInt(b.percent)) return 1;
            return 0;
          })
        ).then(() => {
          this.criacaoPreenchimentoCharts(this.dadosParaOComponenteFuncionar);
        });
      },
      criacaoPreenchimentoCharts(dadosCharts) {
        this.chartData = [];
        for (const element of dadosCharts) {
          const chartClone = JSON.parse(JSON.stringify(this.chart));
          const porcentagem = this.porcentagemPorDoisSemNulo(
            element.respostas,
            element.liderados
          );
          const codigoCor = this.condicaoCor(porcentagem);
          chartClone.options.colors[0] = codigoCor;
          chartClone.series[0] = porcentagem;
          this.chartData.push(chartClone);
        }
      },
      condicaoCor(porcentagem) {
        if (porcentagem == 0 || porcentagem == "") {
          return "#e9ecef";
        } else if (porcentagem > 0 && porcentagem <= 19) {
          return "#AA228C";
        } else if (porcentagem >= 20 && porcentagem <= 44) {
          return "#002060";
        } else if (porcentagem >= 45 && porcentagem <= 74) {
          return "#2772C0";
        } else if (porcentagem >= 75 && porcentagem <= 99) {
          return "#7030A0";
        } else if (porcentagem == 100) {
          return "#36BA00";
        }
      },
      porcentagemPorDoisSemNulo(valorX, valorY) {
        if (valorY != 0) {
          return ([valorX / valorY] * 100).toFixed(0);
        } else {
          return '0';
        }
      },
    },
    watch: {
      FaseSelecionada: function () {
        this.buscaDadosCardTaxaRespostaEquipe();
      },
    },
  }
  </script>
  
  <style scoped>
  .height-custom {
    height: 240px;
  }
  .ordenacao{
    justify-content: right;
    display: flex;
    margin: 5px;
  }
  .alfaBtn{
      padding: 5px;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 0px;
  }
  .percentBtn{
      padding: 5px;
      border-radius: 0px;
  }
  .scroll-area{
    max-height:300px;
  }
  </style>
  