<template>
    <div class="p-1">
      <app-timeline>
        <app-timeline-item
            v-for="(item, id) in this.Fases"
            :key="id"
            @click.prevent="atualizarCards(item)"
            class="clicavel"
            >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
              <h6>Fase {{ item.id_fase }}</h6>
              <p class="text-muted font-weight-bolder fonte14">{{ formatarDataMesAno(item.inicio) }} - {{ formatarDataMesAno(item.termino) }}</p>
          </div>
          <p class="text-muted mt-n1">{{ item.participantes }} participantes | {{ item.respostas }} respostas</p>
        </app-timeline-item>
      </app-timeline>
    </div>
  </template>
  
  <script>
  import {
    BCard
  } from 'bootstrap-vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  
  export default {
    components: {
      BCard,
      AppTimeline,
      AppTimelineItem
    },
    props: {
      Fases: {
        type: Array,
        default() {
          return [];
        },
        required: true,
      },
      FaseSelecionada: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
    },
    data() {
      return {
      }
    },
    methods: {
      formatarDataMesAno(data){
        const nomeMes = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        const objetoData = this.parseDate(data);
        return nomeMes[objetoData.getMonth()] + " " + objetoData.getFullYear();
      },
      parseDate(input) {
        let parts = input.match(/(\d+)/g);
        return new Date(parts[0], parts[1]-1, parts[2]);
      },
      atualizarCards(cicloSelecionado){
        this.$emit("AtualizarCicloSelecionado", cicloSelecionado);
      }
    }
  }
  </script>
  <style scoped>
  .timeline-item-point {
      background-color: #2772C0 !important;
  }
  </style>
  