<template>
<div>
  <div v-if="dados && dados.length > 0">
    <b-overlay
        v-if="loadingShow"
        :show="loadingShow"
        rounded="lg"
        opacity="0.6"
        class="mt-2"
      />
    <div>
      <b-card-body v-if="verificaGrafico">
        <legenda-mudanca-habitos />
        <vue-perfect-scrollbar  :settings="settings">
          <b-row>
            <b-col xl="4" lg="4" md="4">
              <div>
                <MudancaDeHabitosGrafico
                  :FaseSelecionada="FaseSelecionada"
                  :validadorCard.sync="validarCardSemDados"
                  :dados="dadosAlimentacao"
                  :rotulo="'Alimentação'"
                />
              </div>
            </b-col>
            <b-col xl="4" lg="4" md="4">
              <div>
                <MudancaDeHabitosGrafico
                  :FaseSelecionada="FaseSelecionada"
                  :validadorCard.sync="validarCardSemDados"
                  :dados="dadosEstresse"
                  :rotulo="'Estresse'"
                />
              </div>
            </b-col>
            <b-col xl="4" lg="4" md="4">
              <div>
                <mudanca-de-habitosGrafico
                  :FaseSelecionada="FaseSelecionada"
                  :validadorCard.sync="validarCardSemDados"
                  :dados="dadosAtividadeFisica"
                  :rotulo="'Atividade Física'"
                />
              </div>
            </b-col>
          </b-row>
        </vue-perfect-scrollbar>
      </b-card-body>
    </div>
  </div>
  <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
      <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
</div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BCardTitle,
  BOverlay,
} from "bootstrap-vue";
import MudancaDeHabitosGrafico from "@/views/components/custom/dashboard-planeja-saude/MudancasDeHabitosGrafico.vue";
import LegendaMudancaHabitos from "@/views/components/custom/dashboard-planeja-saude/LegendaMudancaHabitos.vue";
import vSelect from "vue-select";
import CardSemDados from "./CardSemDados.vue"
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    MudancaDeHabitosGrafico,
    BRow,
    BCol,
    BOverlay,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
    LegendaMudancaHabitos,
    vSelect,
    CardSemDados,
    VuePerfectScrollbar,
  },
  props: {
    FaseSelecionada: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
    DataCicloTempoReal: {
      type: String,
      default() {
        return {};
      },
      required: true,
    },
    dados: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
  },
  methods: {
    verificaTipoGrafico() {
      this.dados.forEach((item) => {
        if(item.habito == 'Alimentação'){
          this.dadosAlimentacao.push(item)
        }else if(item.habito == 'Estresse'){
          this.dadosEstresse.push(item)
        }else if(item.habito == 'Atividade Física'){
          this.dadosAtividadeFisica.push(item)
        }
      })

      this.verificaGrafico = true;
    }
  },

  mounted() {
    this.verificaTipoGrafico();
    this.loadingShow = false
  },
  data() {
    return {
      reRender: true,
      loadingShow: true,
      dadosAlimentacao: [],
      verificaGrafico:false,
      dadosEstresse: [],
      dadosAtividadeFisica: [],
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro: 0,
      },
      selecionado: "Total",
      opcoesSelect: ["Total", "Diabetes"],

      lista: [],
    };
  }
};
</script>

<style scoped>
 .height-custom{
    height:240px;
  }
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.legenda{
  margin-left: 0px;
}
.legenda-items{
  display: flex;
  align-items: center;
  justify-content: center
}
.legenda_item{
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 13px;
    margin-left: 10px;
}

.bola{
  width: 12px;
  height: 12px;
  border-radius: 12px;
  min-width: 12px;
}
.dias {
  background: #36BA00;
}
.meses {
  background: #2772C0;
}
.algumdia {
  background: #002060;
}

.semmud {
  background: #7030A0;
}

.naopre {
  background: #AA228C;
}
.cor-escala-zero{
  background-color: #36BA00;
}
.cor-escala-um{
  background-color: #2772C0;
}
.cor-escala-dois{
  background-color: #002060;
}
.cor-escala-tres{
  background-color: #7030A0;
}
.cor-escala-quatro{
  background-color: #AA228C;
}
.texto-esquerda{
  text-align: left;
}
.espacamento-vertical-select{
  margin-bottom: 30%;
}
.espacamento_select_top{
  margin-top: 3rem;
}
.style-chooser #vs1__combobox {
  height:50px;
  width: 100%;
  font-size: 14px;
}
.bullet{
  margin-right: 8%;
}
.espacamento_nDoencas{
  margin-right: -30%;
}
.espacamento_escalas_grafico{
  margin-left: 5%;
}
.altura_grafico{
  height:700px;
}
@media (max-width: 2560px) and (min-width: 2000px) {
  .espacamento-vertical-select{
    margin-bottom: 18%;
  }
}
@media (max-width: 2560px) and  (min-width: 2401px) {
  .espacamento_nDoencas{
    margin-right: -35%;
  }
}
@media (max-width: 2400px) and (min-width: 2281px) {
  .espacamento_nDoencas{
    margin-right: -34%;
  }
}
@media (max-width: 2280px) and (min-width: 2000px) {
  .espacamento_nDoencas{
    margin-right: -32%;
  }
}
@media (max-width: 1750px) {
  .espacamento_nDoencas{
    margin-right: -28%;
  }
}
@media (max-width: 1600px) {
  .style-chooser {
    margin: 0 -20px;
  }
  .espacamento_nDoencas{
    margin-right: -24%;
  }
  .espacamento_escalas_grafico{
    margin-left: 0;
  }
  .altura_grafico{
    height:700px;
  }
}
@media (max-width: 1440px) {
  .style-chooser {
    margin: 0 -30px;
  }
  .espacamento_nDoencas{
    margin-right: -20%;
  }
  .espacamento-vertical-select{
    margin-bottom: 25%;
  }
}
@media (max-width: 1380px) {
  .espacamento_nDoencas{
    margin-right: -20%;
  }
  .espacamento-vertical-select{
    margin-bottom: 20%;
  }
}
@media (max-width: 1299px) {
  .espacamento-vertical-select{
    margin-bottom: 15%;
  }
}
@media (max-width: 1199px) {
  .altura_grafico{
    height:1360px;
  }
  .espacamento-vertical-select{
    margin-bottom: 5%;
  }
  .espacamento_nDoencas{
    margin-left: 26%;
    margin-right: -34%;
  }
  .espacamento_escalas_grafico{
    margin-left: 0;
  }
  .style-chooser #vs1__combobox {
    height:50px;
    width: 100%;
    font-size: 14px;
  }
  .bullet{
    margin-right: 8%;
  }
  .espacamento_select_top{
    margin-top: -20px;
  }
}
@media (max-width: 991px) {
  .espacamento_nDoencas{
    margin-left: 18%;
    margin-right: -29.5%;
  }
  .espacamento-vertical-select{
    margin-bottom: 8%;
  }
  .card_header_responsivo{
    text-align: left;
    display: block !important;
  }
    .altura_grafico{
    height:1360px;
  }
}
@media (max-width: 850px) {
  .espacamento_nDoencas{
    margin-left: 15%;
    margin-right: -27%;
  }
}
@media (max-width: 767px) {
  .espacamento_nDoencas{
    margin-left: 25%;
    margin-right: -33.5%;
  }
  .altura_grafico{
    height:1280px;
  }
}
@media (max-width: 650px) {
  .espacamento_nDoencas{
    margin-right: -32%;
  }
}
@media (max-width: 575px) {
  .espacamento_nDoencas{
    margin-left: 10%;
    margin-right: -29%;
  }
  .altura_grafico{
    height:1260px;
  }
}
@media (max-width: 500px) {
  .espacamento_nDoencas{
    margin-left: 10%;
    margin-right: -25%;
  }
}
@media (max-width: 420px) {
  .espacamento-vertical-select{
    margin-bottom: 10%;
  }
  .altura_grafico{
    height:1200px;
  }
}
@media (max-width: 400px) {
  .espacamento_nDoencas{
    margin-left: 10%;
    margin-right: -22%;
  }
}
@media (max-width: 391px) {
  .altura_grafico{
    height:1360px;
  }
}
@media (max-width: 380px) {
  .altura_grafico{
    height:1330px;
  }
}
@media (max-width: 350px) {
  .espacamento_nDoencas{
    margin-left: 0;
    margin-right: -20.5%;
  }
  .altura_grafico{
    height:1310px;
  }
}
@media (min-width: 320px) and (max-width: 335px) {
  .espacamento_nDoencas{
    margin-right: -18%;
  }
  .altura_grafico{
    height:1240px;
  }
}
</style>
