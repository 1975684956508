<template>
  <section>
    <b-overlay
      v-if="loadingShow"  
      class="mt-5"
      :show="loadingShow"
      rounded="lg"
      opacity="0.6"
    />
    <b-overlay :show="loadingAtualizationShow" rounded="lg" opacity="0.6">
      <div v-if="withoutCycles">
        <b-col xl="5" lg="6">
          <EmptyMiniDashboard moduleLabel="Planeja Saúde" />
        </b-col>
      </div>

      <div v-else>
        <card-resumos 
            v-if="!loadingShow"
            :FaseSelecionada="faseSelecionada"
            :dados="planejaResumos"
            :key="keyAtualizacaoCards"
          />
        <b-row v-if="!loadingShow">
          <b-col lg="4" md="5">
            <ciclo-andamento
              :FaseSelecionada="faseSelecionada"
              :key="keyAtualizacaoCards"
            />

            <b-card no-body>
              <div class="altura_grafico_taxa_resposta">
                <taxa-resposta-equipe
                  :dados="taxaRespostaEquipe"
                  :FaseSelecionada="faseSelecionada"
                  :key="keyAtualizacaoCards"
                />
              </div> 
            </b-card>
    
            <b-card no-body>
              <div class="altura_grafico_fases_realizadas">
                <titulo-generico titulo="Fases Realizadas" />
                <fases-realizadas
                  :Fases="fasesDaEmpresa"
                  :FaseSelecionada="faseSelecionada"
                  @AtualizarCicloSelecionado="
                    atualizaFaseSelecionadaDoDashboard
                  "
                />
              </div>
            </b-card>
          </b-col>

          <b-col lg="8" md="7">
            <b-card no-body>
              <div class="altura_grafico_classificacao">
                <titulo-generico titulo="Classificação de Saúde dos Colaboradores" :subtitulo="'Atualizado em '+dataCicloTempoReal"/>
                <classificacao-saude
                  :FaseSelecionada="faseSelecionada"
                  :DataCicloTempoReal="dataCicloTempoReal"
                  :key="keyAtualizacaoCards"
                  :dados="classificacaoSaude"
                />
              </div>
            </b-card>
            <b-card no-body>
              <div class="altura_grafico_doencas_cronicas">
                <titulo-generico titulo="Doenças Crônicas"/>
                <doencas-cronicas-grafico
                  :dados="doencasCronicas"
                  :FaseSelecionada="faseSelecionada"
                  :DataCicloTempoReal="dataCicloTempoReal"
                  :key="keyAtualizacaoCards"
                />
              </div>
            </b-card>
            <b-card no-body>
              <div class="altura_grafico_mudanca_habito">
                <titulo-generico titulo="Prontidão para Mudanças de Hábitos" :subtitulo="'Atualizado em '+dataCicloTempoReal" />
                <mudancas-habitos
                  :FaseSelecionada="faseSelecionada"
                  :DataCicloTempoReal="dataCicloTempoReal"
                  :dados="mudancasHabitos"
                  :key="keyAtualizacaoCards"
                />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import { BRow, BCol, BOverlay, BProgress, BCard } from "bootstrap-vue";
import CicloAndamento from "@/views/components/custom/dashboard-planeja-saude/CicloAndamento.vue";
import FasesRealizadas from "@/views/components/custom/dashboard-planeja-saude/FasesRealizadas.vue";
import DoencasCronicasGrafico from "@/views/components/custom/dashboard-planeja-saude/DoencasCronicasGrafico.vue";
import TituloGenerico from "@/views/components/custom/dashboard-saude-mental/TituloGenerico.vue";
import CardResumos from "@/views/components/custom/dashboard-planeja-saude/CardResumos.vue";
import TaxaRespostaEquipe from "@/views/components/custom/dashboard-planeja-saude/TaxaRespostaEquipe.vue";
import CiclosRealizados from "@/views/components/custom/dashboard-planeja-saude/CiclosRealizados.vue";
import ClassificacaoSaude from "@/views/components/custom/dashboard-planeja-saude/ClassificacaoSaude.vue";
import MudancasHabitos from "@/views/components/custom/dashboard-planeja-saude/MudancasDeHabitos.vue";
import EmptyMiniDashboard from "@/views/pages/miscellaneous/bem-vindo-component/EmptyMiniDashboard.vue";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BCard,
    BProgress,
    CicloAndamento,
    DoencasCronicasGrafico,
    TaxaRespostaEquipe,
    CiclosRealizados,
    MudancasHabitos,
    ClassificacaoSaude,
    FasesRealizadas,
    CardResumos,
    EmptyMiniDashboard,
    TituloGenerico
    
  },
  data() {
    return {
      fasesDaEmpresa: [],
      faseSelecionada: null,
      loadingAtualizationShow: false,
      loadingShow: true,
      dataCicloTempoReal: null,
      keyAtualizacaoCards: 0,
      planejaResumos: [],
      classificacaoSaude: [],
      carregaDados: false,
      doencasCronicasTotal:  [],
      mudancasHabitos: [],
      doencasCronicasAvc: [],
      doencasCronicasArtriteRealmatismo: [],
      doencasCronicasAsmaBronquite: [],
      doencasCronicasColesterol: [],
      doencasCronicasCancer: [],
      doencasCronicasDepressao: [],
      doencasCronicasDiabetes: [],
      doencasCronicasHipertensao: [],
      doencasCronicas: [
        {
          descricao: "Total",
          dados: [
           
          ]
        },
        {
          descricao: "avc",
          dados: []
        },
        {
          descricao: "artrite_reumatismo",
          dados: []
        },
        {
          descricao: "asma_bronquite",
          dados: []
        },
        {
          descricao: "colesterol_triglicerides",
          dados: []
        },
        {
          descricao: "cancer",
          dados: []
        },
        {
          descricao: "depressao",
          dados: []
        },
        {
          descricao: "diabetes",
          dados: []
        },
        {
          descricao: "hipertensao",
          dados: []
        }
      ],
      dadosCardFase: {
        allFases: [],
        selectedFase: null,
      },
      taxaRespostaEquipe: [],
      withoutCycles: false,
      data: {
        cicloAndamento: {},
        taxaRespostaEquipe: {},
        ciclosRealizados: {},
        escalaRespostas: {},
      },
    };
  },

  async beforeMount() {
    await this.buscaDadosCardCiclos();
  },
  methods: {

    async buscaCidades(uf) {
      this.loadingShow = false;
      let rotaUf = this.$helpers.preparaRotaComParametros(
        this.$api.CidadesBuscarPorUF,
        "{uf}",
        uf
      );
      await this.$http.get(rotaUf).then((res) => {
        this.cidadeDisabled = false;
        this.cidades = res.data.cidades;
      });
      this.loadingShow = true;
    },

    async buscaDados(){
      await Promise.all([
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicasTotal, "doencasCronicasTotal"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasAvc", "AVC (Acidente Vascular Cerebral)"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasArtriteRealmatismo", "Artrite ou Reumatismo"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasAsmaBronquite", "Asma ou Bronquite"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasColesterol", "Colesterol ou Triglicérides Alto"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasCancer", "Câncer"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasDepressao", "Depressão"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasDiabetes", "Diabetes"),
        this.buscaDadosCard(this.$api.DashboardPlanejaDoencasCronicas, "doencasCronicasHipertensao", "Hipertensão Arterial"),
        this.buscaDadosCard(this.$api.DashboardPlanejaHabitos, "mudancasHabitos"),
        this.buscaDadosCard(this.$api.DashboardPlanejaResumo, "planejaResumos"),
        this.buscaDadosCard(this.$api.DashboardPlanejaClassificacaoSaude, "classificacaoSaude"),
        this.buscaDadosCard(this.$api.DashboardPlanejaCardTaxaRespostaEquipe, "taxaRespostaEquipe"),
      ]);

      //Card de Doencas Cronicas
      this.doencasCronicas[0].dados = this.doencasCronicasTotal
      this.doencasCronicas[1].dados = this.doencasCronicasAvc
      this.doencasCronicas[2].dados = this.doencasCronicasArtriteRealmatismo
      this.doencasCronicas[3].dados = this.doencasCronicasAsmaBronquite
      this.doencasCronicas[4].dados = this.doencasCronicasColesterol
      this.doencasCronicas[5].dados = this.doencasCronicasCancer
      this.doencasCronicas[6].dados = this.doencasCronicasDepressao
      this.doencasCronicas[7].dados = this.doencasCronicasDiabetes
      this.doencasCronicas[8].dados = this.doencasCronicasHipertensao

      this.cardResumos = true
    
    },

    async buscaDadosCard(apiLink, stringPropDados, doenca = null){ 
      const usuario = this.$store.state.sessions.userData;
      if (!usuario?.empresa.id_empresa) {
        this.$router.push({ name: "error-404" });
      }
      const params = {
        idEmpresa:  usuario.empresa.id_empresa,
        idFase: this.faseSelecionada.id_fase,
        nomeDoenca: doenca
      }

      await this.$http
      .get(apiLink, { params })
      .then((res) => {
        if (res.status == 204) {
          this.$set(this, stringPropDados, [])
        } else {
          this.$set(this, stringPropDados, res.data)
        }
      })
      .catch(() => {
        this.$router.push({ name: "error-404" });
      });
    },

    async atualizaFaseSelecionadaDoDashboard(objectFase){
      this.loadingAtualizationShow = true
      this.dadosCardFase.selectedFase = objectFase
      this.faseSelecionada = objectFase;
      await this.buscaDados({
        idFase: this.dadosCardFase.selectedFase.id_fase,
        idEmpresa: this.dadosCardFase.selectedFase.id_empresa,
      });
      this.keyAtualizacaoCards++
      this.loadingAtualizationShow = false
    },


    async buscaDadosCardCiclos() {
      const usuario = this.$store.state.sessions.userData;
      if (!usuario?.empresa.id_empresa) {
        this.$router.push({ name: "error-404" });
      }
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.DashboardPlanejaCiclos,
        "{idEmpresa}",
         usuario.empresa.id_empresa
      );
      await this.$http
        .get(rota)
        .then(async(res) => {
          if (res.status == 204) {
            this.withoutCycles = true;
          } else {
            this.fasesDaEmpresa = res.data;
            this.faseSelecionada = this.fasesDaEmpresa[0];
            this.faseSelecionada.id = 1;
            this.dataCicloTempoReal = this.progressoFase(
              this.faseSelecionada.inicio,
              this.faseSelecionada.termino
            );
            await this.buscaDados().then(async () => {
            })
          }
          this.loadingShow = false;
        })
        .catch((_error) => {
          this.$router.push({ name: "error-404" });
        });
    },
    buscarDadosCardCiclosPorCicloSelecionado(cicloSelecionado) {
      this.faseSelecionada = cicloSelecionado;
    },
    parseDate(input) {
      let parts = input.match(/(\d+)/g);
      return new Date(parts[0], parts[1] - 1, parts[2]);
    },
    formatarData(data) {
      const objetoData = data ? this.parseDate(data) : new Date();
      return objetoData.toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },
    progressoFase(dataInicio, dataFim) {
      let objetoDataInicio = this.parseDate(dataInicio);
      let objetoDataFim = this.parseDate(dataFim);
      let objetoDataNow = new Date();
      if (objetoDataInicio > objetoDataNow) {
        return this.formatarData() + " - Ciclo ainda não iniciado";
      } else if (
        objetoDataInicio < objetoDataNow &&
        objetoDataFim > objetoDataNow
      ) {
        return this.formatarData();
      } else if (objetoDataFim < objetoDataNow) {
        return this.formatarData(dataFim);
      }
    },
  },
};
</script>

<style lang="scss">
.fonte14 {
  font-size: 14px;
}
.altura_grafico_classificacao{
  height: 375px;
}
.fonte18 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}
.clicavel {
  cursor: pointer;
}

@media(max-width: 1200px) {
    .altura_grafico_classificacao{
      height: auto;
    }
}
</style>
